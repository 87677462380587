import { ThemeProvider, useTheme } from './components/ThemeContext';
import GlobalStyles from './components/styles/GlobalStyles';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';
import Home from './pages/Home';
// import MultiForm from './pages/MultiForm';
// import PropertyManagers from './pages/PropertyManagers';
import MultiFormWhiteLabel from './pages/MultiFormWhiteLabel';
// import NewLead from './pages/NewLead';
// import NewLeadSimple from './pages/NewLeadSimple';
// import RequestAdvanceForm from './pages/RequestAdvanceForm';
import Application from './pages/Application';
import Privacy from "./pages/Privacy";
import NotFoundPage from './pages/NotFoundPage';
import MarkdownPage from './pages/MarkdownPage';

const App = () => {
  return (
    <ThemeProvider>
      <Content />
    </ThemeProvider>
  );
};

const Content = () => {
  const { theme } = useTheme();

  return (
    <>
      <HelmetProvider>
      <GlobalStyles theme={theme} />
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          {/* <Route exact path="/property-managers" element={<PropertyManagers />} /> */}
          {/* <Route exact path="/unlock" element={<RequestAdvanceForm />} /> */}
          {/* <Route exact path="/demo" element={<MultiFormWhiteLabel />} /> */}
          <Route exact path="/unlock" element={<Application />} />
          {/* <Route exact path="/application" element={<Application />} /> */}
          {/* <Route exact path="/privacy" element={<Privacy />} /> */}
          <Route exact path="/:postSlug" element={<MarkdownPage />} />
          {/* <Route exact path="/unlock-your-rental-income" element={<NewLead />} />
          <Route exact path="/unlock" element={<NewLeadSimple />} /> */}
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Router>
      </HelmetProvider>
    </>
  );
}

export default App;