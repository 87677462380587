import React from "react";
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import arrow from '../images/Arrow.svg';

const FlexContainer = styled.div`
  padding: 1.6rem;  
  margin: auto;
  max-width: 1100px;
`;

const BlogDate = styled.small`
  margin-bottom: .5rem;
  display: inline-block;
  font-weight: 600;
  color: gray;
`;

const BlogTitle = styled.h3`
  font-size: 1.4rem;
  letter-spacing: -1px;
  margin-bottom: .4rem;
`;

const BlogCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media (min-width: 810px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const BlogCard = styled.div`
  width: 100%;
  padding: .8rem;
  background-color: white;
  box-shadow: 0px 0px 60px 0px rgba(255, 199, 1, 0.24);
  border-radius: 8px;
  border: 1px solid #f3f3f3;
  transition: transform 0.3s ease;
  text-align: left;
  cursor: pointer;

  &:hover {
    transform: translateY(-4px);
  }

  @media (min-width: 1100px) {
    width: 50%;
  }
`;

const BlogImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 4px;
  margin-bottom: 1rem;
`;

const BlogSubtitleText = styled.p``;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: right;
  margin-top: 1rem;
`;

const BlogLinkButton = styled.button`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #FFC102;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;

  &:hover {
    background-color: #e0a800;
  }

  img {
    width: 24px;
    height: 24px;
  }
`;

const Blogs = () => {
  const navigate = useNavigate();

  const handleNavigate = (path) => {
    navigate(path);
  };

  return (
    <FlexContainer>
      <h2>Deed's and Dont's</h2>
      <BlogCardContainer>
        <BlogCard onClick={() => handleNavigate('/deep-dive-on-deposits.md')}>
          <BlogImage src={"https://lefi-blog-bucket.s3.ap-southeast-2.amazonaws.com/houseThumbnail.png"} alt="Blog 1" />
          <BlogDate>October 9, 2024</BlogDate>
          <BlogTitle>Deep Dive on Deposits.</BlogTitle>
          <BlogSubtitleText>When it comes to buying an investment property, a common misconception is that you need to fully fund the deposit from your savings.</BlogSubtitleText>
          <ButtonContainer>
            <BlogLinkButton onClick={(e) => { e.stopPropagation(); handleNavigate('/deep-dive-on-deposits.md'); }}>
              <img src={arrow} alt="arrow icon" />
            </BlogLinkButton>
          </ButtonContainer>
        </BlogCard>
        <BlogCard onClick={() => handleNavigate('/why-property-managers.md')}>
          <BlogImage src={"https://lefi-blog-bucket.s3.ap-southeast-2.amazonaws.com/portfolio.png"} alt="Blog 2" />
          <BlogDate>October 9, 2024</BlogDate>
          <h3>Why Property Managers?</h3>
          <BlogSubtitleText>You can unlock more than time with a good property manager, here's how!</BlogSubtitleText>
          <ButtonContainer>
            <BlogLinkButton onClick={(e) => { e.stopPropagation(); handleNavigate('/why-property-managers.md'); }}>
              <img src={arrow} alt="arrow icon" />
            </BlogLinkButton>
          </ButtonContainer>
        </BlogCard>
      </BlogCardContainer>
    </FlexContainer>
  );
};

export default Blogs;