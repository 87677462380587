import React from "react";
import styled from "styled-components";
import Nav from "../components/Nav";
import {CTA} from "../components/styles/GlobalStyles"
import Footer from "../components/Footer";

const NotFoundPage = () => {
    return(
        <>
        <Nav />
        <header>
            <CTA>
                <h1>Page Not Found</h1>
                <p>Oops, the page your are looking for cannot be found :/</p>
            </CTA>
        </header>
        <br/>
        <br/>
        <br/>
        <br/>
        <Footer />
        </>
    );
}

export default NotFoundPage;