import React, {useState} from "react";
import { useNavigate } from 'react-router-dom';
import styled, { keyframes } from "styled-components";
import Nav from "../components/Nav";
import Blogs from "../components/Blogs";
import FrequentlyAskedQuestions from "../components/FAQ";
import Footer from "../components/Footer";
import { CTA, RentForm, BackgroundImage, Wrapper, LeFiPoints,GridContainer3 } from "../components/styles/GlobalStyles";
import Cards from "../components/Cards";
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet-async';

import ContactUs from "../components/ContactUs";
import VideoTestimonial from "../components/VideoTestimonial";


import tick from "../images/tick.svg"
import arrow from "../images/Arrow.svg";
import bg from "../images/bg.svg";

import arrowDown from "../images/arrowDown.svg";
import RequestAdvanceFormCopy from "./RequestAdvanceFormCopy";
import hiw1 from "../images/weeklyRent.png";
import hiw2 from "../images/banks.png";
import hiw3 from "../images/payout.png";
import hiw4 from "../images/tenanted.png";
import hiw5 from "../images/lease2.png";

import capstack from "../images/capstack.svg"

import samCircle from "../images/sam_circle.png";

  const variants = {
    hidden: {
      opacity: 0,
      y: 50, // adjust value as needed
    },
    visible: {
      opacity: 1,
      y: 0,
    },
  };

  const variants2 = {
    hidden: {
      opacity: 0,
      x: 0, // adjust value as needed
      // scale: .2
    },
    visible: {
      opacity: 1,
      x: 0,
      // scale: 1
    },
  };


const LeFiComparison = styled.section`
padding: 0;
margin: 2em 1.6em 0em 1.6em;
@media (min-width: 1000px) {
  margin: 1em auto 1em auto;
}
max-width: 1100px;
  @media(min-width: 900px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    height: 800px;
    grid-gap: 1em;
  }
  div {
    border: .8px solid #dedede;
    position: relative;
    background-color: #f3f3f3;
    border-radius: 8px;
    min-height: 320px;
    margin-bottom: 1em;
    .text-container {
      padding: 0 !important;
      border: none !important;
    }
    &:first-of-type {
       border-bottom: 1.6px solid white;
       padding: 4.2em 2.4em 3.2em 2.4em;
       display: flex;
       flex-direction: column;
       justify-content: space-between;
       min-height: 0;
    }
    &:nth-of-type(2) {
      height: 720px;
      @media (min-height: 560px) {
        height: auto;
      }
    }
    h2 {
      margin-bottom: 1em;
    }
    .graph {
      width: 100%;
      max-height: 88%;
      position: absolute;
      bottom: 1.6em;
      left: 0;
      @media (min-height: 560px) {
        padding-top: 1em;
      }
      @media(min-width: 900px) {
        width: 100%;
      }
    }
    ul {
      list-style: none;
      margin-top: 3.2rem;
      li {
        img {
           margin-right: 1rem;
        }
        p {
          font-size: 14px;
          @media(min-width: 560px) {
            font-size: 16px;
          }
        }
        display: flex;
        justify-content: space-between;
      }
    }
  }
  }
`;

const Disclaimer = styled.div`
      list-style: none;
      font-size: 14px;
      text-align: center;
      color: gray;
      display: block;
      li {
        padding: .4em;
        margin: 1.6rem auto;
        text-align: center;
        list-style: none;
      }
`;

const point = keyframes`
  0%, 100% {
    transform: translateY(-150%);
  }
  50% {
    transform: translateY(-100%);
  }
`;

const StyledArrowDown = styled.img`
      margin: auto;
      width: 16px;
      position: absolute;
      left: 49.5%;
      transform: translateY(-125%);
      animation: ${point} 1s infinite;
`;

const HowItWorksStack = styled.section`
  position: relative;
  max-width: 1100px;
  margin: auto;
  padding: 1.6em;
  img {
    position: absolute;
    z-index: -1;
    right: -1px;
    bottom: -1px;
    width: 280px;
    @media (min-width: 756px) {
      width: 320px;
    }
  }
  div.grid {
    h2 {
      position: relative;
      // border: 1px solid red;
      letter-spacing: -1.5px;
      font-size: 28px;
      @media (max-width: 420px) {
        font-size: 24px;
      }
    }
    .one {
      &::before {
        content: "1.";
        position: absolute;
        left: -1em;
        z-index: 10;
      }
    }
    .two {
      &::before {
        content: "2.";
        position: absolute;
        left: -1em;
        z-index: 10;
      }
    }
    .three {
      &::before {
        content: "3.";
        position: absolute;
        left: -1em;
        z-index: 10;
      }
    }
    .four {
      &::before {
        content: "4.";
        position: absolute;
        left: -1em;
        z-index: 10;
      }
    }
    .five {
      &::before {
        content: "5.";
        position: absolute;
        left: -1em;
        z-index: 10;
      }
    }
    // @media (max-width: 900px) {
    //   grid-template-columns: 1fr;
    //   height: 100%;
    // }
    display: Grid;
    // grid-template-columns: 1fr 1fr;
    width: 100%;
    position: sticky;
    top: 78px;
    background-color: #ffc803;
    border-radius: 8px;
    margin-bottom: 1em;
    overflow: hidden;
    height: 500px;
    @media (min-width: 760px) {
      height: 320px;
      p {
        max-width: 720px;
      }
    }
    box-shadow: 0px 4px 21.6px 5px rgba(0, 0, 0, 0.08);
  }
  .text-container {
    margin: .5em 2em;
    padding: 1em;
    display: block;
    h2 {
      margin-bottom: .5em;
      color: black;
    }
    p {
      color: #000;
    }
  }
  // .img-container {
  //   // @media (max-width: 900px) {
  //     display: block;
  //     height: 250px;
  //   // }
  //   width: 100%;
  //   overflow: hidden;
  //   position: relative;
  //   img {
  //     position: absolute;
  //     bottom: 0;
  //     right: 0;
  //     height: 100%;
  //   }
  // }
`;

const CapStackComp = styled.section`

    width: 100%;
    max-width: 1000px;
    margin: 1rem auto;
    padding: 2em 2.4em;
    border-bottom: 1px solid #f3f3f3;
    h2 {
        margin-bottom: .5em;
    }
    p {
      padding-right: 1em;
    }
    margin: auto;
    @media (min-width: 720px) {
      display: flex;
      flex: 1;
    }
    div {
      width: 100%;
      margin-bottom: 0em;
      @media (min-width: 720px) {
        margin-bottom: 0em;
        img {
          float: right;
        }
      }
      img {
        margin: 2em auto 0 auto;
        display: block;
      }
    }
    
`

const StyledTestimonial = styled(motion.div)`
    max-width: 260px;
    height: 44px;
    display: flex;
    justify-content: center;
    overflow: hidden;
    align-items: center;
    // padding: 0em .5em;
    // border: 3.2px solid #f3f3f3;
    border-radius: 100px;
    margin: auto;
    background-color: rgb(255, 249, 231);
    p {
      font-size: 16px;
      letter-spacing: -.8px;
      margin-left: .5em;
      color: #686868;
    }
    .imgContainer {
      margin: 0;
      height: 38px;
      width: 38px;
      // border: 2px solid #f3f3f3;
      object-fit: fill;
      overflow: hidden;
      border-radius: 100px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); /* Horizontal offset, Vertical offset, Blur radius, Spread radius, Color */
      img {
        width: 100%;
        height: 100%;
        margin: 0;
      }
    }
`;

const Home = () => {
    // const { theme, toggleTheme } = useTheme();
    const [rent, setRent] = useState(500); 

    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        navigate(`/unlock?weeklyRent=${rent}`);
    };

    return (
        <>
        <Helmet>
        <meta
          name="LeFi"
          content="Rental Income Cash Advances for Managed Properties, Get your Cash Today!"
        />
        <meta name="description" content="Advance 1 month to 2 years of your future rental income today so you can boost cashflow, buy furniture, do a renovation, or secure a deposit today!" />
        <meta property="og:title" content="LeFi - Rental Income Advances" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/leficash.appspot.com/o/opengraph.png?alt=media&token=70ed2c91-0cb0-45fb-bcd8-6ed4298c4f42" />
        <meta property="og:url" content="https://lefi.cash" />
      </Helmet>
       <BackgroundImage src={bg} alt="bg" />

        <Nav />
        <header>
        <CTA>            
            <StyledTestimonial
                                      initial="hidden"
                          animate="visible"
                          variants={variants2}
                          transition={{
                            type: 'spring',
                            stiffness: 120, // Adjust as needed
                            damping: 10,    // Adjust as needed
                            mass: 0.75,     // Adjust as needed
                            delay: 1.5,     // 0.5s delay
                          }}
            >
            <motion.div                           initial="hidden"
                          animate="visible"
                          variants={variants2}
                          transition={{
                            type: 'spring',
                            stiffness: 120, // Adjust as needed
                            damping: 10,    // Adjust as needed
                            mass: 0.75,     // Adjust as needed
                            delay: 1.5,     // 0.5s delay
                          }}className="imgContainer"><img src={samCircle} alt="sam"></img></motion.div> <p>Sam Unlocked: &nbsp; <span>$35,000</span></p></StyledTestimonial>
            <h1>
              Turn Tomorrow&apos;s Rent <br/>into Today&apos;s Rent
            </h1>
            <p>
              Ditch Personal loans and credit cards for our fast and low risk rental income advances and access upto $100k to manage cashflow,  renovate  or finance a new deposit. Zero Commitment Applications & Zero impact on your Credit File.
            </p>

            <RentForm onSubmit={handleSubmit}>
            <motion.div
              initial="hidden"
              animate="visible"
              variants={variants}
              transition={{
                type: 'spring',
                stiffness: 120, // Adjust as needed
                damping: 10,    // Adjust as needed
                mass: 0.75,     // Adjust as needed
                delay: 1,     // 0.5s delay
              }}
            >
                <label htmlFor="rentInput">Your Rental Income / Week?</label><br />
                <div className="inputWrapper">
                    <input 
                        style={{outline: "none"}}
                        id="rentInput"
                        name="rentInput"
                        type="number"
                        placeholder="500"
                        onChange={e => setRent(e.target.value)}
                    />
                    <button type="submit"><img src={arrow} alt="arrow" /></button>
                </div>
                {/* <p>Apply for Cash</p> */}
            </motion.div>
          </RentForm>
        </CTA>

        <StyledArrowDown src={arrowDown} alt="down arrow" />

        <Cards weeklyRent={rent} />

        </header>



        <LeFiPoints>
          <Wrapper>
          <h2>What makes us special?</h2>
          <GridContainer3>
            <div>
              <img src={tick} alt="tick" />
              <h3>Non-Debt Capital</h3>
              <p>Unlike a loan, this isn't debt. We simiply bring your future rental income forward.</p>
            </div>
            <div>
              <img src={tick} alt="tick" />
              <h3>Non-Equity Capital</h3>
              <p>Our capital doesn't impact your upside potential, it unlocks it.</p>
            </div>
            <div>
              <img src={tick} alt="tick" />
              <h3>Zero Interest Rate Risk</h3>
              <p>This means the fixed amount you owe will never change even if interest rates do.</p>
            </div>
            <div>
              <img src={tick} alt="tick" />
              <h3>Super Fast</h3>
              <p>We are the fastest and most simple way to extract cash from your investment property. Get funded in as fast as 24 hours.</p>
            </div>
            <div>
              <img src={tick} alt="tick" />
              <h3>Short Duration</h3>
              <p>Now you don’t have to refinance for another 30 years to access capital or use personal credit cards and loans when your cash flow needs aren't being met.</p>
            </div>
            <div>
              <img src={tick} alt="tick" />
              <h3>Zero Hidden Fees</h3>
              <p>We are committed to total transperancy and simplicity. The only fee we charge is a one time establishment fee which is a portion of your advance balance paid in advance.</p>
            </div>
          </GridContainer3>
          </Wrapper>
        </LeFiPoints>

        {/* <section> */}
        {/* <AdvanceBreakdownStatic totalAdvanceAmount={rent*52/2} weeklyRent={rent} termInMonths={36} /> */}
        {/* <Disclaimer>
           <ul> */}
            {/* <li>* this breakdown is meant for illustrative purpouses only, your rental advance may vary.</li> */}
           {/* </ul>
        </Disclaimer>
        </section> */}

        
<HowItWorksStack>
          <h2>How It Works</h2>
          <br/>
          <br/>

          <div className="grid">
            <div className="text-container">
              <h2 className="one">Customise and Apply</h2>
              <p>Tell us about you and your property and we will let you know how much of your future rental income you could extract!</p>
            </div>
            {/* <div className="img-container">
              <img src={hiw1} alt="how it works step 1" />
            </div> */}
            <img src={hiw1} alt="how it works step 1" />
          </div>

          <div  className="grid">
            <div className="text-container">
              <h2 className="two">Receive Funds</h2>
              <p>Your funds get deposited directly into your bank account, simple!</p>
            </div>
            {/* <div className="img-container">
              <img src={hiw2} alt="how it works step 1" />
            </div> */}
            <img src={hiw2} alt="how it works step 2" />
          </div>

          <div  className="grid">
            <div className="text-container">
              <h2 className="three">Your Property Manager Manages the Payouts</h2>
              <p>Your existing tenant keeps paying rent and your property manager splits the rental income between the landlord and LeFi so you don't have to manage a thing.</p>
            </div>
            <img src={hiw3} alt="how it works step 3" />
          </div>

          <div  className="grid">
            <div className="text-container">
              <h2 className="four">Your Tenant Stays in the Property</h2>
              <p>After you advance your rent, nothing changes but your cash position. Your existing tenant keeps paying rent and you have the flexibility to manage or scale your portfolio as you please.</p>
            </div>
            <img src={hiw4} alt="how it works step 4" />

          </div>
          <div  className="grid">
            <div className="text-container">
              <h2 className="five">In Between Tenants? No Problem!</h2>
              <p>Unlike a bank loan, you don't repay it directly. Instead, we receive a portion of your rental income managed by your property manager. If your property is vacant, you won't be penalized.</p>
            </div>
            <img src={hiw5} alt="how it works step 5" />

          </div>
        </HowItWorksStack>    

        <CapStackComp>
            <div className="flex-wrapper">
              <h2>Upgrade your Capital Stack</h2>
              <p>You could upgrade your capital stack to the tune of another $100,000. Now your cashflow isn't constrained by your tenant or by time.</p>
              <br/>
            </div>
            <div>
              <img src={capstack} alt="capital stack"></img>
            </div>
        </CapStackComp>

        <VideoTestimonial />
    
                  
        <section style={{position: "relative", overflow: "hidden"}}>
          {/* <BackgroundImage src={bg} alt="bg" style={{opacity: 1, top: "-500px", left: "1000px", width: "2000px"}}/>  */}
          <br></br>
          <br></br>
          <br></br>
          <br></br>
            <Wrapper>
            <h2>Customise an Advance</h2>
            <br/>
              <p style={{maxWidth: "560px"}}>What makes LeFi so powerful is that the future rental income that you advance, can be repaid over the long term. This is so you can retain your cashflow and cover all necessary expenses without sacrificing growth!</p>
              <br/>
            <br></br>
            <br></br>
            <br></br>
            <RequestAdvanceFormCopy />
            </Wrapper>
          </section>
       
        <FrequentlyAskedQuestions />

        {/* <Resources/> */}
        {/* <LongOrShortTerm /> */}
        
        <Blogs />

        <ContactUs/>
        <Footer />
      </>
    )
}

export default Home;