import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import matter from 'gray-matter';
import { Buffer } from 'buffer';
import { Helmet, HelmetProvider } from 'react-helmet-async';
//components
import Nav from '../components/Nav';
import Footer from '../components/Footer';
import NotFoundPage from '../pages/NotFoundPage';

window.Buffer = Buffer;

const MarkdownPage = () => {
  const { postSlug } = useParams();
  const navigate = useNavigate();
  const [markdownContent, setMarkdownContent] = useState('');
  const [frontmatter, setFrontmatter] = useState({});
  const [error, setError] = useState(null);
  const [firstImageUrl, setFirstImageUrl] = useState(null);

  const fileUrl = `https://lefi-blog-bucket.s3.ap-southeast-2.amazonaws.com/${postSlug}`;

  useEffect(() => {
    const fetchMarkdown = async () => {
      try {
        const response = await fetch(fileUrl);
        if (!response.ok) {
          throw new Error('File not found or access denied');
        }

        const text = await response.text();
        const { data, content } = matter(text);
        setFrontmatter(data);

        // Extract the first image URL
        const firstImgMatch = content.match(/!\[.*?\]\((.*?)\)/);
        if (firstImgMatch) {
          setFirstImageUrl(firstImgMatch[1]);
          // Remove the first image from the content
          setMarkdownContent(content.replace(firstImgMatch[0], '').trim());
        } else {
          setMarkdownContent(content.trim());
        }

        console.log('Parsed frontmatter:', data);
        console.log('Parsed content:', content);
      } catch (err) {
        console.error('Error fetching markdown:', err);
        setError('Error: Blog post not found.');
        navigate('*');
      }
    };

    fetchMarkdown();
  }, [fileUrl, navigate]);

  if (error) {
    return <NotFoundPage />;
  }

  return (
    <HelmetProvider>
      <Helmet>
        <title>{frontmatter.title || 'Blog Post'}</title>
        <meta name="description" content={frontmatter.subtitle || ''} />
        <meta name="author" content={frontmatter.author || ''} />
        <meta name="keywords" content={frontmatter.keywords ? frontmatter.keywords.join(', ') : ''} />
        <meta property="og:title" content={frontmatter.title || 'Blog Post'} />
        <meta property="og:description" content={frontmatter.subtitle || ''} />
        <meta property="og:image" content={frontmatter.coverImage || ''} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={`https://your-domain.com/blog/${postSlug}`} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      <Nav />
      <div style={{ width: '100%', overflowX: 'hidden' }}>
        <header>
        <div style={{ margin: '0 auto', padding: '20px', maxWidth: '800px' }}>
          <h1>{frontmatter.title}</h1>
          <h2>{frontmatter.subtitle}</h2>
          <p>{frontmatter.date && new Date(frontmatter.date).toDateString()}</p>
        </div>
        </header>

        {firstImageUrl && (
          <div style={{ width: '100vw', marginLeft: 'calc(-50vw + 50%)', marginRight: 'calc(-50vw + 50%)', maxHeight: '600px', overflow: 'hidden' }}>
            <img src={firstImageUrl} alt="Cover Image" style={{ width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center' }} />
          </div>
        )}

        <div style={{ margin: '0 auto', padding: '20px', maxWidth: '800px' }}>
          <ReactMarkdown 
            remarkPlugins={[remarkGfm]}
            components={{
              img: ({node, ...props}) => <img {...props} alt={props.alt || ''} style={{ maxWidth: '100%', height: 'auto' }} />
            }}
          >
            {markdownContent}
          </ReactMarkdown>
        </div>
      </div>
      <Footer />
    </HelmetProvider>
  );
};

export default MarkdownPage;