import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import {ButtonRoundedText } from "../components/styles/GlobalStyles";
import {sendSlackNotification, handleSendEmail} from "../helpers/index.js";
import { useNavigate } from 'react-router-dom';



const StyledGrid = styled.div`
    margin-bottom: 2em;
    display: grid;
    grid-template-columns: 1fr; /* Single column by default */
    gap: 5.6em; /* Adjust the gap between grid items */
    @media screen and (min-width: 768px) {
        grid-template-columns: 2fr 4fr; /s* 2 columns on larger screens */
        gap: 3em; /* Adjust the gap between grid items */
    }
    @media screen and (min-width: 1000px) {
        grid-template-columns: 2fr 3fr; /* 2 columns on larger screens */
    }
    h3 {
        font-weight: 600;
        font-size: 24px;
    }
    p {
    margin-bottom: 0 !important;
    }
    div.sperator {
        margin-bottom: 1em;
    }
`;


const AdvanceForm = styled.form`
    box-shadow: 0px 4px 16px 9px rgba(0, 0, 0, 0.08);           
    padding: 1.6em 0 0 0;
    background-color: white;
    border: 1.6px solid #dedede;
    border-radius: 16px;
    overflow: hidden;
    label {
        text-align: left;
        padding: 0;
        font-weight: 600;
        text-decoration: underline;
        line-height: 2;
    }
    small {
        display: block;
        width: 80%;
        line-height: 1.4;
    }
    select {
        border-radius: 8px;
        box-shadow: none !important;
        text-align: left;
        font-weight: 500 !important;
        border: 1px solid #dedede !important;
        outline-offset: 1px;
        option {
            font-weight: 400 !important;
        }
    }
    .item-container {
        padding: 1em 1.6em;
        p {color: black;}
    }
    .bg-strong {
        background-color: rgb(255, 249, 231);
        // background-color: white;

    }
    .bg-alert {
        background-color: rgba(255, 0, 0, 0.16);
    }
    
    .button-container {
        margin-top: 2em;
    }
    .weeklyRent {
        margin-left: .5em !important;
    }

    }
    .dollar-sign {
        position: relative;
        &:before {
            content: "$";
            font-weight: 600;
            position: absolute;
            top: 50%;
            left: 1em;
            z-index: 10;
            transform: translateY(calc(-50% - .5em));
        }
`;

const InputStrong = styled.input`
    position: relative;
    padding: .8em .5em;
    font-size: 40px;
    border: 1.6px solid  #dedede;
    border-radius: 8px;
    font-weight: 500;
    box-shadow: 0px 0px 3.5px 3px rgba(0, 0, 0, 0.06) inset;
`;

const FlexContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const UserPropertyDetailsForm = styled.form`
    div.form-divider {
        margin-bottom: 4em;
        &:last-of-type {
            margin-bottom: 0;
        }
    }
    h3 {
        margin-bottom: 1em;
        letter-spacing: -1.2px;
    }
    label {
        text-align: left;
        font-weight: 600;
        text-decoration: underline;
        padding-left: 0;
    }
    input {
        border-radius: 100px;
        margin: 0 0 1em 0;
        text-align: left;
        padding: .8em 1em;
        outline: none !important;
    }

    select {
        border-radius: 8px;
        margin: 0 0 1em 0;
        text-align: left;
        background-color: #f3f3f3;
        box-shadow: none !important;
        border: 1px solid #dedede !important;
        outline-offset: 1px;
    }
    p {
        margin-bottom: .2em;
        // background-color: #f9f9f9;
        padding: .5em 1em;
        font-size: 14px;
        border-radius: 8px;
    }
`;

const StrongInputWrapper = styled.div`
    position: relative;
    &:before {
        content: "$";
        font-size: 32px;
        font-weight: 500;
        position: absolute;
        z-index: 10;
        top: 50%;
        left: 1em;
        transform: translateY(-50%);
    }
`;

const RequestAdvanceForm = (props) => {
    const [error, setError] = useState(''); // State to store error messages
    const [message, setMessage] = useState(''); // State to store error messages
    const [isRequesting, setIsRequesting] = useState(false);

    const [formData, setFormData] = useState(
        {
            advanceAmount: 26000,
            term: 3,
            weeklyRent: '500',
        }
        );

    const navigate = useNavigate();

    const query = useQuery();

    function useQuery() {
        return new URLSearchParams(useLocation().search);
      }
    
    const weeklyRentFromURL = query.get('weeklyRent');

    useEffect(() => {
        if (weeklyRentFromURL) {
        setFormData(prevState => ({
            ...prevState,
            weeklyRent: weeklyRentFromURL,
        }));
        }
    }, [weeklyRentFromURL]);

    const totalAdvance = (advanceAmount, term) => {
        return (advanceAmount * (1 + (.1 * term)));
    };

    const principlalRepayments = (advanceAmount, term) => {
        return (advanceAmount/(term*52));
    };

    const costRepayments = (advanceAmount, term) => {
        return ((advanceAmount * (1 + (.1 * term))- advanceAmount) / (term * 52));
    };

    const totalWeeklyRepayment = (advanceAmount, term) => {
        return (advanceAmount * (1 + (.1 * term)) / (term*52));
    };

    const twoYearsRent = (formData.weeklyRent * 56 * 2).toLocaleString();
    const oneYearsRent = (formData.weeklyRent * 56).toLocaleString();
    // const sixMonthsRent = (formData.weeklyRent * 28).toLocaleString();

    const weeklyRentalIncome = (formData.weeklyRent);

    const weeklyRepayment = ((formData.advanceAmount * (1 + (.1 * formData.term)) / (formData.term * 52))); /////wrong

    const rentalIncomeRemaining = ((((formData.weeklyRent / 7) * 365 )/ 52) - weeklyRepayment);

    const minAdvance = 5000;
    // const maxAdvance = formData.weeklyRent * 104;

    const bgClass = rentalIncomeRemaining < 0 ? 'bg-alert' : 'bg-strong';


    const handleSubmit = async (e) => { 
        e.preventDefault();
        setIsRequesting(true);

        if ( formData.weeklyRent === "" || formData.weeklyRent === null ) {
            setError('Please add your weekly rent');
            setIsRequesting(false);
            return;
        }
            // sendSlackNotification(formData);
            // await handleSendEmail(formData);
            setIsRequesting(false);
            // setMessage("Your request was sent, a team member will get in touch with you shortly!");
            navigate(`/unlock?weeklyRent=${formData.weeklyRent}`);
            return;
    };

    return (
        <>
            <StyledGrid>
                <div>
                    <UserPropertyDetailsForm onSubmit={handleSubmit}>
                        <div className='form-divider'>
                                {/* <h3>Your Investment Property</h3> */}
                                <label>Your Weekly Rental Income</label>
                                <div className="dollar-sign">
                                <input 
                                    className='weeklyRent'
                                    style={{backgroundColor: "#fff", paddingLeft: "2.2em", border: "3.2px solid #dedede", borderRadius: "100px", fontWeight: "500", textAlign: "center"}} 
                                    type="number"
                                    placeholder="Weekly Rental Income" 
                                    value={formData.weeklyRent}
                                    onChange={e => setFormData({ ...formData, weeklyRent: parseFloat(e.target.value)})}
                                    />
                                </div>
                                {/* <label>Your Rent is Paid:</label>
                                <select style={{borderRadius: "100px", fontWeight: 500, textAlign: "center", outline: "none"}}>
                                    <option>Weekly</option>
                                    <option>Fortnightly</option>
                                    <option>Monthly</option>
                                </select> */}
                                <div className='button-container'>
                                    <ButtonRoundedText type="submit" disabled={isRequesting}>{isRequesting ? 'Sending...' : 'Unlock your Rent' }</ButtonRoundedText>   
                                </div>
                        </div>
                        <p>* Zero commitment applications / No impact on your Credit File</p>
                        <p>* Illustrative purposes only, your advance may vary</p>

                        {error && <p style={{color: 'red', fontSize: '14px', textAlign: "center", marginTop: "2em", padding: ".5em", borderRadius: "100px", backgroundColor: "rgba(255, 0, 0, 0.06)"}}>{error}</p>}
                        {message && <p style={{color: 'black', fontSize: '14px', textAlign: "center", marginTop: "2em", padding: ".5em", borderRadius: "8px"}}>{message}</p>}
                            
                        </UserPropertyDetailsForm>

                    </div>
                    
                    <div>

                    <AdvanceForm onSubmit={handleSubmit}>
                        
                        <div className="item-container">
                            <label>Rental Income Advance Amount</label>
                            <br/>
                            <br/>

                            <StrongInputWrapper>
                            <InputStrong 
                                className={bgClass}
                                type="text" 
                                value={formData.advanceAmount.toLocaleString()} // Format the value
                                onChange={e => {
                                const formattedValue = e.target.value.replace(/,/g, ''); // Remove existing commas
                                setFormData({ ...formData, advanceAmount: parseFloat(formattedValue) || 0 });
                            }}
                                />
                            </StrongInputWrapper>
                            <FlexContainer style={{marginTop: "1em", justifyContent: "space-between !important"}}>
                            <small>Min: ${minAdvance.toLocaleString('en-US', {style: 'decimal',  useGrouping: true})}</small>
                            <small style={{textAlign: "center"}}>1 Year: ${oneYearsRent}</small> 
                           <small style={{textAlign: "right"}}>2 Years: ${twoYearsRent}</small> 
                            </FlexContainer>
                        </div>

                        <br></br>
                        
                        <div className="item-container">
                            {/* <label>Repayment Term</label>
                            <p style={{color: "gray", letterSpacing: "-.2px", fontSize: "14px", fontWeight: 400}}>
                                A longer repayment period reduces your monthly repayments.
                            </p>
                            <select 
                                type="select"
                                value={formData.term}
                                onChange={e => setFormData({ ...formData, term: e.target.value })}
                                >
                                <option value="3">3 Years</option>
                                <option value="2">2 Years</option>
                                <option value="1">1 Year</option>
                                <option value=".5">6 Months</option>
                            </select>
                            <br/>
                            <br/> */}

                            <FlexContainer>
                                <label>Weekly Principal Repayment</label><p>${principlalRepayments(formData.advanceAmount, formData.term).toLocaleString('en-US', {style: 'decimal', maximumFractionDigits : 2, minimumFractionDigits: 2, useGrouping: true})}</p>
                            </FlexContainer>
                            
                            <FlexContainer>
                                <label>Weekly Cost to LeFi Payment</label><p>${costRepayments(formData.advanceAmount, formData.term).toLocaleString('en-US', {style: 'decimal', maximumFractionDigits : 2, minimumFractionDigits: 2, useGrouping: true})}</p>
                            </FlexContainer>
                             <br/>

                            <FlexContainer>
                                <label>Total Repayment Amount</label><p>${totalAdvance(formData.advanceAmount, formData.term).toLocaleString('en-US', {style: 'decimal', maximumFractionDigits : 2, minimumFractionDigits: 2, useGrouping: true})}</p>
                            </FlexContainer>
                            <p style={{color: "gray", letterSpacing: "-.2px", fontSize: "14px", fontWeight: 400}}>This is the amount you would repay in total over 3 years, all advance amounts are marked up 10% p.a</p>
                            {/* <p style={{color: "gray", letterSpacing: "-.2px", fontSize: "14px", fontWeight: 400}}>36 Month Repayment Term (3 years)</p> */}


                            <br/>

                        </div>

                        <div className="item-container">
                             <FlexContainer>
                                <label>Weekly Rental Income</label><p>${weeklyRentalIncome.toLocaleString('en-US', {style: 'decimal', maximumFractionDigits : 2, minimumFractionDigits: 2, useGrouping: true})}</p>
                            </FlexContainer>
                            <br/>
                            <FlexContainer>
                                <label>Weekly Repayment</label><p style={{fontWeight: "500"}}>${totalWeeklyRepayment(formData.advanceAmount, formData.term).toLocaleString('en-US', {style: 'decimal', maximumFractionDigits : 2, minimumFractionDigits: 2, useGrouping: true})}</p>
                            </FlexContainer>
                            <br/>
                        </div>

                        <div className={`item-container ${bgClass}`} style={{paddingBottom: "4em"}}>
                            <FlexContainer>
                                <label>Weekly Rental Income Remaining</label><p style={{fontWeight: "600"}}>${rentalIncomeRemaining.toLocaleString('en-US', {style: 'decimal', maximumFractionDigits : 2, minimumFractionDigits: 2, useGrouping: true})}</p>
                            </FlexContainer>
                            <p style={{color: "gray", letterSpacing: "-.2px", fontSize: "14px", fontWeight: 400}}>
                                This means you will receive a cash boost of  ${formData.advanceAmount.toLocaleString('en-US', {style: 'decimal', maximumFractionDigits : 2, minimumFractionDigits: 2, useGrouping: true})} and also receive ${rentalIncomeRemaining.toLocaleString('en-US', {style: 'decimal', maximumFractionDigits : 2, minimumFractionDigits: 2, useGrouping: true})} in rental income every week after your repayment to LeFi has been made.
                            </p>
                        </div>
                    </AdvanceForm>
                </div>
            </StyledGrid>
            {/* </section> */}
        </>
    );
};

export default RequestAdvanceForm;