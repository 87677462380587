import React, { useState } from 'react';
import styled from 'styled-components';
import plus from "../images/plus.svg"

const FAQContainer = styled.div`
    width: 100%;
    max-width: 1100px;
    margin: 3.2rem auto;
    padding: 1.6em;
    h2 {
        margin-bottom: 1em;
    }
`;

const FAQItem = styled.div`
    margin-bottom: .2rem;
    border-radius: 4px;
    border-bottom: 1px solid #f3f3f3;
        @media (max-width: 720px) {
     margin-bottom: .5rem;
    }
`;

const FAQQuestion = styled.p`
    font-weight: 600;
    color: #393939;
    letter-spacing: -0.8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .8rem 0rem;
    cursor: pointer;
    font-size: 19px;
    margin-bottom: 0;
    @media (max-width: 720px) {
      padding: .5rem 0em .5em .5em;
    }
`;

const FAQIcon = styled.span`
    transform: rotate(0deg);
    transition: transform 0.3s ease-in-out;
    ${props => props.active && `
        transform: rotate(45deg);
    `}
`;

const FAQAnswer = styled.p`
    transition: transform 0.3s ease-in-out;
    padding: 1rem 0rem;
    font-size: 1em; // Regular font size for answers
    color: #666; // Lighter color for the answer for differentiation
`;

const FAQ = () => {

    const [activeIndex, setActiveIndex] = useState(null);

    const faqs = [
        {
            question: "What is LeFi?",
            answer: "LeFi, short for Lease Finance, is a fintech company that assists landlords and real estate entrepreneurs in optimizing their cash flow through rental income advances."
        },
        {
            question: "What type of properties do we fund?",
            answer: "We fund real estate entrepreneurs who own investment properties managed by property managers, owners of short-term rentals, as well as real estate entrepreneurs who sublet rental properties."
        },
        {
            question: "How does a Rent Advance work?",
            answer: "A Rent Advance with LeFi allows landlords to receive a lump sum payment equivalent to several months' rent upfront. We then receive a portion of your rent going forward, which is distributed by your property manager."
        },
        {
            question: "How are the advances repaid?",
            answer: "All advances are repaid from your rental income by your property manager. Advances are not directly repaid by the landlord."
        },
        {
            question: "What are the term lengths?",
            answer: "LeFi offers various term lengths of up to 36 months, depending on the landlord's needs and the property type."
        },
        {
            question: "How much does a LeFi Advance cost?",
            answer: "Advances are marked up by approximately 10% per year, and the repayments are spread over a maximum of 36 months so you retain cash flow."
        },
        {
            question: "Does LeFi impact my credit rating?",
            answer: "No, LeFi does not impact your credit rating. We only run soft credit checks."
        },
        {
            question: "What if my tenant is in arrears?",
            answer: "If a tenant is in arrears (vacant or not paying rent), the rate at which LeFi recoups their advance is simply delayed until the property is tenanted and cash-flowing again."
        },
        {
            question: "Is LeFi a financial product?",
            answer: "No, LeFi is not a financial product. We provide cash advances through writing a new lease with limited rights on your property."
        },
        {
            question: "Is a LeFi Advance Taxed as Income?",
            answer: "A LeFi advance is generally seen as 'unearned/deferred income' and isn't taxed when you receive it as you need to record a liability against it. The landlord would continue to recognize the full rental income received from the tenant each year. The cost of the advance may also be used as a deduction. <br><br> **Please seek independent financial advice and consult with your accountant/financial professional before making any financial decisions. This information is general in nature and may not apply to your specific situation."
        },
    ];
    
    

    return (
        <FAQContainer>
            <h2>Frequently Asked Questions</h2>
            {faqs.map((faq, index) => (
                <FAQItem key={index}>
                    <FAQQuestion onClick={() => setActiveIndex(index === activeIndex ? null : index)}>
                        {faq.question}
                        <FAQIcon active={index === activeIndex}><img src={plus} alt="plus" /></FAQIcon>
                    </FAQQuestion>
                    {index === activeIndex && <FAQAnswer dangerouslySetInnerHTML={{ __html: faq.answer }} />}
                </FAQItem>
            ))}
        </FAQContainer>
    );
};

export default FAQ;
